import {
  Box,
  Card,
  CardContent,
  Rating,
  Typography
} from '@customink/pigment-react';
import {CardActionArea} from '@customink/pigment-react/lib/components/Card';
import React from 'react';

const ProductDetailsCard = ({
  complimentBadge,
  imageSrc,
  imageSrcSet,
  linkHref,
  minQty,
  name,
  onClick,
  ratingCount,
  ratingScore,
  sizing
}) => {
  return (
    <Card sx={{display: 'flex'}}>
      <CardActionArea
        href={linkHref}
        onClick={onClick}
        aria-label={name}
        aria-hidden
        tabIndex={-1}
        sx={{
          display: 'flex',
          flexDirection: {md: 'column', sm: 'row'}
        }}>
        <Box
          component="img"
          sx={{
            aspectRatio: '1 / 1.1',
            display: 'block',
            height: {md: 'unset', sm: '100%'},
            maxHeight: '16rem',
            objectFit: 'contain',
            width: {md: '100%', sm: '6rem'}
          }}
          src={imageSrc}
          srcSet={imageSrcSet}
          alt={name}
        />
        <CardContent
          sx={{
            display: 'flex',
            flex: '1',
            flexDirection: 'column',
            gap: '0.3125rem',
            height: '100%',
            justifyContent: {md: 'flex-start', sm: 'center'},
            mt: 0,
            padding: {md: '0.8125rem 1rem', sm: '0.5rem'},
            width: {md: '100%', sm: 'unset'}
          }}>
          <Typography variant="bodyLong1">{name}</Typography>
          {ratingScore && ratingCount ? (
            <Box sx={{margin: '-1px 0 1px -1px'}}>
              <Rating
                precision={0.1}
                value={Number(ratingScore)}
                ratingCount={ratingCount}
                size="small"
              />
            </Box>
          ) : null}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              lineHeight: '1.25',
              mt: '0.0625rem',
              width: '100%'
            }}>
            <Typography variant="captionRegular">{sizing}</Typography>
            <Typography variant="captionRegular">&ensp;•&ensp;</Typography>
            <Typography variant="captionRegular">
              {minQty === 1 ? 'No Minimum' : `Minimum ${minQty}`}
            </Typography>
          </Box>
          {complimentBadge && complimentBadge.toUpperCase()}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ProductDetailsCard;
