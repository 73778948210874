import {Link} from '@customink/pigment-react';
import React from 'react';

const SizeAvailabilityLink = ({text}) => {
  const url = `${window.location.pathname}/sizing`;

  const handleClick = () => {
    window.open(
      url,
      'sizingcolors',
      'width=700,height=700,scrollbars=yes,toolbar=yes,menubar=no,location=yes,resizable=yes'
    );
  };

  return (
    <Link
      variant="captionRegular"
      onClick={handleClick}
      role="link"
      tabIndex="0">
      {text}
    </Link>
  );
};

export default SizeAvailabilityLink;
