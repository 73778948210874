import {Box} from '@customink/pigment-react';
import React from 'react';
import DeliveryRushChip from '../components/DeliveryRushChip';

const SUPER_RUSH_DAYS = 3;

const getDeliverRushLabel = (numberOfDays) => {
  if (numberOfDays <= SUPER_RUSH_DAYS) {
    return `${numberOfDays}-Day Super Rush Available`;
  }

  if (numberOfDays === 7) {
    return `1-Week Rush Available`;
  }

  return `${numberOfDays}-Day Rush Available`;
};

const ElevateSuperRush = ({deliveryRushDays}) => {
  const isSuperRush = deliveryRushDays <= SUPER_RUSH_DAYS;

  return (
    <Box className="pc-ProductCard-detailDelivery">
      <DeliveryRushChip
        label={getDeliverRushLabel(deliveryRushDays)}
        superRush={isSuperRush}
      />
    </Box>
  );
};

export default ElevateSuperRush;
