import {
  getUploadManipulateUrl,
  getUploadUrl
} from '../src/apis/upload_service_client';
import {customPreviewMaxDimension} from './constants/mmsImagesConstants';

export const customDesignPreviewColorCount = (customDesignPreview) => {
  const colorCount = Object.keys(customDesignPreview.data.colors).length;
  if (
    customDesignPreview.data.background &&
    customDesignPreview.data.colors[customDesignPreview.data.background]
  ) {
    return colorCount - 1;
  }
  return colorCount;
};

export const createSingleColorCustomDesignPreviewUrl = (
  customDesignPreview
) => {
  const previousUrlSearchParams = new URL(customDesignPreview?.url)
    .searchParams;
  const singleColorPreviewUrl = new URL(
    getUploadManipulateUrl(
      customDesignPreview.data.url.replace('/uploads/', ''),
      `?${previousUrlSearchParams}`
    )
  );
  singleColorPreviewUrl.searchParams.set(
    'width',
    customDesignPreview.data.dimensions.width
  );
  singleColorPreviewUrl.searchParams.set('red', '0');
  singleColorPreviewUrl.searchParams.set('green', '0');
  singleColorPreviewUrl.searchParams.set('blue', '0');
  singleColorPreviewUrl.searchParams.set('contrast', '200');
  singleColorPreviewUrl.searchParams.set('autonegate', '1');
  return singleColorPreviewUrl;
};

const hexColorDigits = (color = '') => {
  return color.replace('#', '');
};

// Parses UploadService response and extracts url for the temporary user upload
export const createCustomDesignPreviewUploadUrl = (
  uploadServiceResponseData = {}
) => {
  let uploadUrl = `${getUploadUrl(uploadServiceResponseData.url)}`;

  // If the user upload has a background we want to make it transparent via UploadService's manipulate endpoint.
  // The only color that's going to be marked as transparent is the one that matches the background. We need to list
  // all of them as otherwise, colors present in the image and not listed in edit_colors params will be removed.
  if (uploadServiceResponseData?.background) {
    const colorTriplets = [];
    Object.entries(uploadServiceResponseData.colors).map(
      ([color, colorCIMatch]) => {
        return colorTriplets.push(
          `${hexColorDigits(color)},${hexColorDigits(colorCIMatch)},${color === uploadServiceResponseData.background ? 'transparent' : hexColorDigits(colorCIMatch)}`
        );
      }
    );
    const editColorParam = colorTriplets.join(',');
    const widthParam = uploadServiceResponseData.dimensions.width;
    const params = `?edit_colors=${editColorParam}&width=${widthParam}&max_dimension=${customPreviewMaxDimension}`;
    uploadUrl = getUploadManipulateUrl(
      uploadServiceResponseData.url.replace('/uploads/', ''),
      params
    );
  }

  return uploadUrl;
};

export const setCustomDesignPreviewUrlParams = (
  customDesignPreview,
  url,
  options
) => {
  if (customDesignPreview) {
    // We can't override options.autoNegate because that'd result in permanent change of MMS image url params for a
    // particular style / blank color. When removing the design back to YDH, we'd have to set them back to the default
    // values provided in options of getColorImage and that's be too messy.
    // Only remove autonegate for products where we don't want to transform the design to single color due to product's
    // limited print capabilities (design has more colors than a product supports)
    if (options.singleColorDesign) {
      url.searchParams.delete('singleColorDesign');
    } else {
      url.searchParams.set('autoNegate', '0');
    }
    // Override placeMaxPct for drinkware (dr=1 is used for MMS Images to handle curvature of design preview) as otherwise,
    // it's set to 0.9 and that often results in the design being displayed as way too large.
    if (url.searchParams.get('dr') === '1')
      url.searchParams.set('placeMaxPct', '0.6');
  }
  return url;
};
