import React from 'react';
import PropTypes from 'prop-types';
import { useRefinementList } from 'react-instantsearch';

const ColorFilterGroup = ({filterChangeHandler, attribute, ...remainingProps}) => {
  const { items, refine } = useRefinementList({attribute, ...remainingProps});
  const labelForAttribute = `fs-colors-`;

  const colorFacetAttributeName = attribute.split(".", 1)[0];
  const titleize = value => value.split('-').map(w => w[0].toUpperCase() + w.slice(1)).join(' ');
  const upperCamelCase = color => titleize(color).replace(/ /g, '')
  const colorSelected = item => item.isRefined ? 'is-selected' : '';

  const colorSwatchClicked = (event, item) => {
    event.preventDefault();
    filterChangeHandler();
    window.dataLayer.push({
      event: 'catalog_filter',
      filter_type: 'fs-colors',
      filter_value: item.value,
      filter_action: item.isRefined ? 'remove' : 'add'
    })
    refine(item.value);
  }

  return (
    <nav className={`pc-FiltersGroup pc-FiltersGroup--colors ${colorFacetAttributeName}`}>
      <header className="pc-FiltersGroup-header expanded">Color Family</header>
      <div className='pc-FiltersGroup-values'>
        {items?.map((item) => {
          return (
            <label
              htmlFor={labelForAttribute + item.highlighted}
              key={item.highlighted}
              className='sb-Form-checkbox pc-FiltersGroup-value'
              data-insights-filter={`fs-colors:${item.highlighted}`}
            >
              <span className="sr-only">{titleize(item.value)}</span>
              <input type="checkbox" id={labelForAttribute + item.highlighted} value={item.highlighted} title={titleize(item.value)}/>
              <a className='pc-FiltersGroup-link' role="link" tabIndex="0" onClick={event => colorSwatchClicked(event, item)}>
                <span className="sb-Form-indicator"></span>
                <div title={titleize(item.value)}
                     className={`pc-Swatch is-${upperCamelCase(item.value)} ${colorSelected(item)}`}>
                </div>
              </a>
            </label>
          );
        })}
      </div>
    </nav>
  )
}

ColorFilterGroup.propTypes = {
  attribute: PropTypes.string,
  limit: PropTypes.number,
  sortBy: PropTypes.array
}

export default ColorFilterGroup;
