import React from 'react';
import ListingsHeader from './ListingsHeader';
import SelectedFilters from './SelectedFilters';

function SearchHeader(props) {
  const {
    algoliaClient,
    algoliaIndexPrefix,
    applySearchQuery,
    applyStatData,
    applyUserQuery,
    categoryName,
    currentUserInternal,
    defaultQuoteQty,
    filterGroups,
    hitsLength,
    indexName,
    isMobile,
    onRemoveOrderSize,
    pricingAlgorithm,
    quoteQuantityDirty,
    railsEnv,
    refinementItems,
    scroll,
    searchParams,
    searchQuery,
    searchToRefinementListMap,
    setRefinementItems,
    siteWideSearch,
    statData,
    virtualFilterGroupMenus,
    virtualFilterGroupNumericMenus,
    visibleFilters,
    width
  } = props;

  const filtersCount = visibleFilters.length;

  return (
    <div>
      <ListingsHeader
        categoryName={categoryName}
        indexName={indexName}
        siteWideSearch={siteWideSearch}
        searchQuery={searchQuery}
        hitsLength={hitsLength}
        isMobile={isMobile}
        filtersCount={filtersCount}
        searchParams={searchParams}
        railsEnv={railsEnv}
        pricingAlgorithm={pricingAlgorithm}
        algoliaClient={algoliaClient}
        algoliaIndexPrefix={algoliaIndexPrefix}
        defaultQuoteQty={defaultQuoteQty}
        width={width}
        scroll={scroll}
      />
      <SelectedFilters
        filterGroups={filterGroups}
        searchToRefinementListMap={searchToRefinementListMap}
        indexName={indexName}
        setRefinementItems={setRefinementItems}
        applyUserQuery={applyUserQuery}
        applySearchQuery={applySearchQuery}
        applyStatData={applyStatData}
        siteWideSearch={siteWideSearch}
        visibleFilters={visibleFilters}
        currentUserInternal={currentUserInternal}
        quoteQuantityDirty={quoteQuantityDirty}
        onRemoveOrderSize={onRemoveOrderSize}
        refinementItems={refinementItems}
        virtualFilterGroupMenus={virtualFilterGroupMenus}
        virtualFilterGroupNumericMenus={virtualFilterGroupNumericMenus}
        searchQuery={searchQuery}
        statData={statData}
        pricingAlgorithm={pricingAlgorithm}
        algoliaIndexPrefix={algoliaIndexPrefix}
        defaultQuoteQty={defaultQuoteQty}
      />
    </div>
  );
}

export default SearchHeader;
