import {Box, Button, Carousel, PigmentThemeProvider, Typography} from '@customink/pigment-react';
import React, {useEffect, useState, useRef} from 'react';
import {QueryClientProvider} from 'react-query';
import useRecentSavedDesign from '../../hooks/queries/recentSaveDesign';
import queryClient from '../../src/shared/queryClient';
import {HOST} from '../../constants';
import useDesignTracking from './useDesignTracking';

const CAROUSEL_HEIGHT = '230px';
const SAVE_INTERACTION_LABEL = 'your latest design save display';
const CUSTOM_INTERACTION_LABEL = 'design custom t-shirts display';
const EDIT_INTERACTION_LABEL = 'edit my design click';
const VIEW_INTERACTION_LABEL = 'view my designs click';

const DesignDetails = ({name, product_name, product_color, updated_at}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', paddingTop: '0.5rem'}}>
    <Typography variant="body1" sx={{fontWeight: 'bold', margin: '0.25rem 0'}}>{name}</Typography>
    <Typography variant="captionLarge" sx={{fontSize: '14px'}}>{product_name}</Typography>
    <Typography variant="captionLarge" sx={{fontSize: '14px'}}>
      {product_color} | Last Saved: {updated_at}
    </Typography>
  </Box>
);

const DesignActions = ({onEdit, onView}) => (
  <Box sx={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', paddingTop: '1rem'}}>
    <Button onClick={onEdit}>Edit My Design</Button>
    <Button onClick={onView} variant="secondary">View My Designs</Button>
  </Box>
);

const RecentSavedDesignInner = () => {
  const { data, isLoading } = useRecentSavedDesign();
  const [slides, setSlides] = useState([]);
  const firedInteractions = useRef([]);
  const { trackInteraction } = useDesignTracking();
  
  const {
    front_design,
    back_design,
    name,
    product_name,
    product_color,
    updated_at,
    edit_url
  } = data || {};

  useEffect(() => {
    if (front_design) {
      setSlides([
        <img key="front" src={`${HOST}${front_design}`} alt="Front" />,
        back_design && <img key="back" src={`${HOST}${back_design}`} alt="Back" />
      ].filter(Boolean));
      
      const productFind = document.querySelector('.pc-Subcategories-section-product-card.product-find');
      if (productFind) {
        productFind.style.display = 'none';
      }
    }
  }, [front_design, back_design]);

  useEffect(() => {
    if (!isLoading) {
      if (firedInteractions.current.includes(SAVE_INTERACTION_LABEL) || firedInteractions.current.includes(CUSTOM_INTERACTION_LABEL)) {
        return;
      }
      const label = data?.front_design ? SAVE_INTERACTION_LABEL : CUSTOM_INTERACTION_LABEL;
      trackInteraction(label);
      firedInteractions.current.push(label);
    }
  }, [isLoading, data, trackInteraction]);

  if (isLoading || !slides.length) return null;

  const handleEditDesign = () => {
    trackInteraction(EDIT_INTERACTION_LABEL);
    window.open(`${HOST}${edit_url}`, '_blank');
  };

  const handleViewDesigns = () => {
    trackInteraction(VIEW_INTERACTION_LABEL);
    window.open(`${HOST}/account/designs`, '_blank');
  };

  return (
    <Box sx={{ maxWidth: 245}}>
      <Typography
        sx={{ 
          color: '#242424',
          fontSize: '24px',
          fontWeight: '700',
        }}
      >
        Your Latest Save
      </Typography>
      <Carousel
        sx={{
          marginTop: '0.75rem',
          '.keen-slider__slide': {
            backgroundColor: '#d8d8d8',
            borderRadius: '8px'
          }
        }}
        dots={false}
        sliderHeight={CAROUSEL_HEIGHT}
        slides={slides}
      />
      <DesignDetails 
        name={name}
        product_name={product_name}
        product_color={product_color}
        updated_at={updated_at}
      />
      <DesignActions 
        onEdit={handleEditDesign}
        onView={handleViewDesigns}
      />
    </Box>
  );
};

const RecentSavedDesign = () => {
  return (
    <PigmentThemeProvider>
      <QueryClientProvider client={queryClient}>
        <RecentSavedDesignInner />
      </QueryClientProvider>
    </PigmentThemeProvider>
  );
};

export default RecentSavedDesign;
