import {useQuery} from 'react-query';

const host =
  __ENV__ === 'production'
    ? 'https://www.customink.com'
    : 'https://www-master.staging.customink.com';

const fetchRecentSavedDesign = async () => {
  let email;
  try {
    const info = await StyleBitz.Identity.info();
    email = info.email;
  } catch (error) {
    // Ignore auth failures - unauthenticated users will get empty data
  }

  if (!email) {
    return {data: {}};
  }
  const url = `${host}/sc/api/saved-designs?email=${encodeURIComponent(email)}`;
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error('Failed to fetch recent saved design');
  }
  return response.json();
};

export default function useRecentSavedDesign() {
  return useQuery('recentSavedDesign', fetchRecentSavedDesign);
}
