export const catalogMat = Signalman.featureFlag('catalog_mat');
// prettier-ignore
export const previewUpload = Signalman.featureFlagFromCookie(
  'pc_preview_upload_v19'
);
export const reviewSummarization = Signalman.featureFlag(
  'review_summarization_v1'
);
export const collapsedFilters = Signalman.featureFlag('collapsed_filters_v3');
export const paidSearchTshirtsCategoryRedesign =
  Signalman.featureFlagFromCookie('psr_custom_t_shirts_catalog_page_v2');
export const newProductCard = Signalman.featureFlag('pc_new_product_card_v1');
