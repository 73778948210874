import {Link, Tooltip, Typography} from '@customink/pigment-react';
import React from 'react';
import {trackRequestSampleHoverEvent} from '../../utils/metrics/productDetailsMetrics';

const tooltipSx = () => ({
  '& .MuiTooltip-arrow': {
    color: 'white'
  },
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0 0 1rem rgba(0, 0, 0, 0.1)',
    color: '#444444',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    maxWidth: '390px'
  }
});

const RequestSampleTooltip = ({children, ttarpNumber}) => {
  return (
    <Tooltip
      onOpen={trackRequestSampleHoverEvent}
      title={
        <>
          <Typography variant="bodyLong1">
            We know how important it is to find the right product style, fit,
            and color. Don&apos;t worry — we&apos;re here to help.
          </Typography>
          <Typography variant="bodyLong1">
            Call us at&nbsp;
            <Link href={`tel:+1-${ttarpNumber}`}>{ttarpNumber}</Link>
            &nbsp; and we will be happy to ship a sample to you, typically for a
            small fee plus shipping costs.
          </Typography>
          <Typography variant="bodyLong1">
            We&apos;re also available to answer any questions you may have about
            our products and can make recommendations based on your particular
            needs.
          </Typography>
        </>
      }
      PopperProps={{sx: tooltipSx}}
      placement="bottom"
      arrow>
      {children}
    </Tooltip>
  );
};

export default RequestSampleTooltip;
