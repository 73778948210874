import {
  Box,
  Skeleton,
  Stack,
  useMobileBreakpoint
} from '@customink/pigment-react';
import React from 'react';

// this is a Placeholder for the Product Card
export default function ProductCard() {
  const isMobile = useMobileBreakpoint();
  const swatchCount = isMobile ? 5 : 15;

  return (
    <Stack
      sx={{
        border: '1px solid #E5E7EB',
        borderRadius: '0.5rem',
        display: 'flex',
        flexDirection: isMobile ? 'row' : 'column'
      }}>
      {/* Product Image */}
      <Box sx={{height: isMobile ? '100%' : 300, width: '100%'}}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            borderBottomLeftRadius: isMobile ? '0.5rem' : '0',
            borderTopLeftRadius: '0.5rem',
            borderTopRightRadius: isMobile ? '0' : '0.5rem'
          }}
        />
      </Box>
      {/* Product Details */}
      <Stack
        gap={isMobile ? '0.5rem' : '0.625rem'}
        p={isMobile ? '12px 8px' : '1rem'}>
        {/* Product Title */}
        <Skeleton variant="text" width="90%" height="1.75rem" />

        {/* Color Swatches */}
        <Stack direction="row" flexWrap="wrap" gap="0.25rem 0.25rem">
          {[...Array(swatchCount)].map((_, index) => (
            <Skeleton
              // TODO: Remove this once we have a real swatch
              // eslint-disable-next-line react/no-array-index-key
              key={`swatch-${index}`}
              variant="rectangular"
              width="1.5rem"
              height="1.5rem"
              sx={{borderRadius: '0.25rem'}}
            />
          ))}
        </Stack>

        {/* Rating */}
        <Stack direction="row" spacing={1} alignItems="center">
          <Skeleton variant="text" width="6.25rem" height={24} />
          <Skeleton variant="text" width="4rem" height={24} />
        </Stack>

        {/* Size Range and Women's Label */}
        <Stack direction="row" gap="0.5rem">
          <Skeleton variant="text" width="4rem" height="1.5rem" />
          <Skeleton variant="text" width="6rem" height="1.5rem" />
        </Stack>

        {/* Rush Badge */}
        <Skeleton
          variant="rectangular"
          width="12.5rem"
          height="1.25rem"
          sx={{borderRadius: '20px'}}
        />

        {/* Price and Details */}
        <Stack justifyContent="space-between" alignItems="flex-start">
          <Skeleton variant="text" width="9.375rem" height="1.5rem" />
          <Skeleton variant="text" width="6.25rem" height="1rem" />
        </Stack>
      </Stack>
    </Stack>
  );
}
