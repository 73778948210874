import './index.scss';
import {Box, Link, Tooltip} from '@customink/pigment-react';
import React, {useState} from 'react';

function PriceDetailsToolTip({priceDetails, onOpenHandler}) {
  const [lastEvent, setLastEvent] = useState(null);

  const handleMouseEnter = (event) => {
    setLastEvent('mouseEnter');
  };
  const handleClick = (event) => {
    event.preventDefault();
    setLastEvent('click');
  };
  const handleOpen = () => {
    onOpenHandler(lastEvent);
  };

  return (
    <Tooltip
      onOpen={handleOpen}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
      aria-hidden="true"
      tabIndex="-1"
      role="link"
      title={
        <Box className="pc-Price-Details-Tooltip-title">{priceDetails}</Box>
      }
      PopperProps={{
        className: 'pc-Price-Details-Tooltip-popper',
      }}>
      <Link className="pc-Price-Details-Tooltip-link" aria-hidden="true" tabIndex="-1" role="link">
        Pricing Details
      </Link>
    </Tooltip>
  );
}

export default PriceDetailsToolTip;
