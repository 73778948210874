const renderingEffects = {
  deboss: 'imdeboss',
  debossMetal: 'imdebossmetal',
  emboss: 'imemboss',
  embroidery: 'imembroider',
  engrave: 'imengrave',
  engraveSteel: 'imengravesteel',
  engraveWood: 'imengravewood'
};
const materials = {
  metal: {
    renderingEffect: renderingEffects.engraveSteel,
    terms: [
      'metal',
      'steel',
      'aluminum',
      'zinc',
      'brass',
      'insulation',
      'silicone',
      'pu fabric',
      'abs plastic',
      'ceramic'
    ],
    type: 'metal'
  },
  wood: {
    renderingEffect: renderingEffects.engraveWood,
    terms: ['wood', 'bamboo'],
    type: 'wood'
  }
};

// Material is not just a specific term but can be a longer sentence containing the material we're looking for.
// Example: "* 18/8 grade stainless steel * design will be laser engraved using no ink or colors"
export const laserEngravingSurface = (material) => {
  if (!material || typeof material !== 'string') return null;
  if (materials.metal.terms.some((term) => material.includes(term)))
    return materials.metal.type;
  if (materials.wood.terms.some((term) => material.includes(term)))
    return materials.wood.type;
  return null;
};

export const addMaterialBasedRenderingEffects = (hit, url) => {
  if (url.searchParams.get('skip_deco_viz')) {
    url.searchParams.delete('skip_deco_viz');
    return url;
  }

  const materialsToEffectsMapping = {
    debossed: renderingEffects.deboss,
    embossed: renderingEffects.emboss,
    embroidery: renderingEffects.embroidery,
    'laser engraved':
      laserEngravingSurface(hit.material?.toLowerCase()) ===
      materials.metal.type
        ? renderingEffects.engraveSteel
        : null
  };
  // If the url already has a rendering effect specified (possibly due to the product's YDH overrides) don't attempt
  // to set the default values based on decoration type or material
  const alreadyHasRenderingEffect = Object.values(renderingEffects).some(
    (effect) => url.searchParams.get(effect)
  );
  // Rendering effect to apply based on the decoration method
  const effect = materialsToEffectsMapping[hit.decoration_method];

  if (alreadyHasRenderingEffect || !effect) return url;

  url.searchParams.set(effect, '1');
  if (
    ['laser engraved', 'embossed', 'debossed'].includes(hit.decoration_method)
  ) {
    url.searchParams.set('autoNegate', '0');
  }
  if (['embossed', 'debossed'].includes(hit.decoration_method)) {
    url.searchParams.set('ixq', '90');
  }

  return url;
};

export const adjustRenderingEffects = (options) => {
  if (!options.digest) options.digest = '000000028';
  delete options.size;
  delete options.extended;
};
