import {Skeleton} from '@customink/pigment-react';
import React from 'react';

export const ProductCardPlaceholders = ({
  firstImage,
  isMobile,
  isPreviewUploadEnabled
}) => {
  const placeholders = [];
  // With preview upload we want to shift the first image preview position to second column as the first one is the
  // preview upload card
  const firstImagePreviewPosition = isPreviewUploadEnabled ? 1 : 0;
  for (let i = 0; i < 6; i += 1) {
    const identifier = `placeholder-${i}`;
    if (i === firstImagePreviewPosition && firstImage) {
      const styles = isMobile
        ? {height: '172px', paddingBottom: '0'}
        : {height: '481px', paddingBottom: '0'};
      placeholders.push(
        <div key={identifier} className="pc-ProductCard" style={styles}>
          <div className="pc-ProductCard-content">
            <a className="pc-ProductCard-link">
              <div className="pc-ProductCard-image">
                <div className="pc-ProductCard-imageWrapper">
                  <img
                    className="pc-ProductCard-imagePrimary"
                    src={firstImage}
                    loading="eager"
                    alt="Product"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      );
    } else {
      placeholders.push(
        <div
          key={identifier}
          className="pc-ProductCard"
          style={{paddingBottom: '0'}}>
          <Skeleton
            height={isMobile ? 172 : 481}
            variant="rectangular"
            width="100%"
          />
        </div>
      );
    }
  }
  return placeholders;
};

function Placeholders({firstImage, isMobile, isPreviewUploadEnabled}) {
  return (
    <div className="algolia-loading">
      <div className="pc-Styles-body sb-Wrapper">
        <div className="pc-Styles-products is-filterSortShown">
          <div className="pc-Styles-breadcrumbs">
            <div className="pc-Breadcrumbs">
              <Skeleton
                height={isMobile ? 21 : 25}
                sx={{marginBottom: isMobile ? '8px' : '4px'}}
                variant="rectangular"
                width="100%"
              />
            </div>
          </div>
          <div className="pc-ToolBar">
            <Skeleton
              height={isMobile ? 30 : 40}
              variant="rectangular"
              width="100%"
            />
            <Skeleton
              height={isMobile ? 40 : 36}
              variant="rectangular"
              width="100%"
            />
          </div>
          <div className="pc-Products">
            <ProductCardPlaceholders
              firstImage={firstImage}
              isMobile={isMobile}
              isPreviewUploadEnabled={isPreviewUploadEnabled}
            />
          </div>
        </div>
        {!isMobile && (
          <div className="pc-Styles-filters">
            <Skeleton height="100vh" variant="rectangular" width="100%" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Placeholders;
