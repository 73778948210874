import {useQuery} from 'react-query';

const baseUrl = (styleId) => {
  return `/products/styles/${styleId}/delivery_options.json`;
};

const fetchDeliveryOptions = async (styleId) => {
  let fetchUrl = baseUrl(styleId);
  // We need to append the query string to the URL if it exists
  // This is necessary for the delivery options to be fetched correctly
  // Since the query string contains the product color and order type etc
  if (window.location.search !== '') {
    fetchUrl += window.location.search;
  }
  const response = await fetch(fetchUrl);
  if (!response.ok) {
    throw new Error('Failed to fetch delivery options', {
      response,
      styleId
    });
  }
  return response.json();
};

const useProductDeliveryOptions = (styleId) => {
  const {
    data = {},
    error,
    isLoading
  } = useQuery({
    queryFn: () => fetchDeliveryOptions(styleId),
    queryKey: ['delivery_options', styleId]
  });

  const deliveryText = data?.deliveryText || '';
  const rushDeliveryText = data?.rushDeliveryText || '';
  const deliveryDetails = data?.details || null;
  const individualShipEligible = data?.individualShipEligible || false;

  return {
    deliveryDetails,
    deliveryText,
    error,
    individualShipEligible,
    isLoading,
    rushDeliveryText
  };
};

export default useProductDeliveryOptions;
