import {filterUrlSearchParams} from '../images';

export const getIxParams = (hit, categoryId, fallbackUrl) => {
  const ixParamsFilter = ([key, _val]) => key.startsWith('ix', 0);
  let catImageData = false;
  let ixParams = false;
  if (hit && hit.category_images) {
    catImageData = hit.category_images.find((cat) => cat.id === categoryId);
    if (catImageData && catImageData.ix_params) {
      ixParams = catImageData.ix_params;
    }
  }
  if (!ixParams && fallbackUrl) {
    ixParams = filterUrlSearchParams(fallbackUrl, ixParamsFilter);
  }
  return ixParams || {};
};

const handleIframerAndCid = (ixParams, iframer, cid) => {
  if (!iframer) return;

  if (cid) {
    ixParams.design = cid;
    ixParams.autoNegate = 0;
  } else {
    delete ixParams.design;
  }
};

export const getIxParamsForCard = (
  hit,
  fallback,
  categoryId,
  iframer = false,
  cid = false
) => {
  const ixParams = getIxParams(hit, categoryId, fallback);
  ixParams.extended = true;
  ixParams.ixw = 270;
  handleIframerAndCid(ixParams, iframer, cid);
  return ixParams;
};
