const useDesignTracking = () => {
  const trackInteraction = (action) => {
    const category = 'catalog subcategory';
    const label = 'popular picks';

    CustomInk.Metrics.fireEvent(category, action, label);
    window.dataLayer.push({
      event: 'interaction',
      interaction_category: category,
      interaction_action: action,
      interaction_label: label,
      interaction_value: label
    });
  };

  return { trackInteraction };
};

export default useDesignTracking;
